import firebase from "firebase";
import React, { Component } from 'react';
import { selectedBlog } from "../actions";
import Loader from '../components/loader';
import Images from "../util/images";
import { connect } from "react-redux";

class Blogs extends Component {

    state = {
        blogs: [],
        loading: true,
        editModalStatus: true
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
            } else {
                this.props.history.push("/login")
            }
        });
        firebase.firestore().collection("blogs")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    console.log(doc.id);
                    let blog = doc.data();
                    blog.id = doc.id;
                    this.setState({
                        blogs: [...this.state.blogs, blog],
                    })
                });
                this.setState({ loading: false })
            })
            .catch(() => {
                this.setState({
                    loading: false
                })
            })
    }

    editBlog = (blogDetails) => {
        this.props.selectedBlog(blogDetails);
        this.props.history.push("/updateBlog")
        this.setState({
            editModalStatus: true,
        })

    }

    toggle = () => {
        this.setState({
            editModalStatus: false
        })
    }

    deleteBlog = (blog) => {
        this.setState({ loading: true, blogs: [] })
        firebase.firestore().collection("blogs").doc(blog.id).delete()
            .then((res) => {
                firebase.firestore().collection("blogs")
                    .get()
                    .then((querySnapshot) => {

                        querySnapshot.forEach((doc) => {
                            let blog = doc.data();
                            blog.id = doc.id;
                            this.setState({
                                blogs: [...this.state.blogs, blog],
                            })
                        });
                        this.setState({ loading: false })
                    })
                    .catch(() => {
                        this.setState({
                            loading: false
                        })
                    })
            })
            .catch((error) => {
                this.setState({ loading: false })
            })
    }

    renderBlogs() {
        // eslint-disable-next-line
        return this.state.blogs.map((blog, index) => {
            return (
                <div style={{ borderWidth: 1, borderColor: "red" }} key={index} className="col-sm-6">
                    <div style={{ display: "flex", justifyContent: "space-between" }} className="row">
                        <div onClick={() => this.deleteBlog(blog)} className="btn btn-primary a-btn-slide-text">
                            <span className="glyphicon glyphicon-remove" aria-hidden="true"></span>
                            <span><strong>Delete</strong></span>
                        </div>
                        <div onClick={() => this.editBlog(blog)} className="btn btn-primary a-btn-slide-text">
                            <span className="glyphicon glyphicon-edit" aria-hidden="true"></span>
                            <span><strong>Edit</strong></span>
                        </div>
                    </div>
                    <div className="masonry-item">

                        <figure>
                            <div className="top-image">
                                {/* // eslint-disable-next-line */}
                                <img src={blog.image_url}
                                    alt="how to create break fast food delicious" />
                            </div>
                            <div className="thumb-image">
                                {/* // eslint-disable-next-line */}
                                <img src={blog.image_url} alt="thumb" />
                            </div>
                            <figcaption>
                                <div className="description">
                                    {blog.title}
                                    <p dangerouslySetInnerHTML={{ __html: blog.content }} ></p>
                                </div>
                            </figcaption>
                        </figure>

                    </div>
                </div>
            )
        })
    }
    render() {
        return (
            // <Theme>
            <div className="theme-container">
                <img className="theme-image" src={Images.slider1} alt="theme" />
                {this.state.loading ? <Loader /> : null}
                {this.state.blogs.length ?
                    <div className="blog-masonry-container blog_classic my-5" >
                        <section className="blog-masonry col-md-12">
                            <div className="row">
                                <div className="container">
                                    <div className="my-5" style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <div onClick={() => this.props.history.push("/updateBlog")} className="btn btn-primary a-btn-slide-text">
                                            <span className="glyphicon glyphicon-plus" aria-hidden="true"></span>
                                            <span><strong>Add</strong></span>
                                        </div>
                                    </div>
                                    <div id="blog-masonry" className="row">
                                        {this.renderBlogs()}
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="paging-nav col-xs-12">
                                            <span className="float-left">
                                                <span className="paging-inline">
                                                    <div className="btn-arrow">
                                                        <i className="icon-left-open-big"></i>
                                                    </div>
                                                </span>
                                                <span className="paging-text paging-inline">
                                                    Older Post
                                                </span>
                                            </span>
                                            <span className="float-right paging-disabled">
                                                <span className="paging-text paging-inline">Newer Post</span>
                                                <span className="btn-arrow paging-inline">
                                                    <i className="icon-right-open-big"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                    : null
                }


            </div>
            // </Theme>
        )
    }
}

export default connect(null, { selectedBlog })(Blogs)