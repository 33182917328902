import React, { Component } from 'react'
import Theme from "./theme";
import { Helmet } from 'react-helmet';

export default class SEO extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Theme>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title> Search Engine Optimization Company | SEO Services | Kudoss IT Solutions</title>
                    <meta name="description"
                        content="Kudoss IT Solutions a search engine optimization company India offers customized seo services,affordable search engine optimization services for small and big business." />
                    <meta name="keywords"
                        content="seo company india, search engine optimization india, search engine optimization company india, search engine optimization services, seo company, seo company, seo company india, seo company in india, seo company india, seo india company, seo services" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="seo wrapper">
                    <div className="container">
                        <div className="row section-wrapper">
                            <div className="section-head col-sm-12">
                                <header className="col col-sm-12 centered">
                                    <section>
                                        <h2>Search Engine Optimization (SEO)</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>
                        <div className="row section-wrapper">
                            <div className="col-sm-12">
                                <p> Now a days, with such intense competition for top search engine placements, getting and maintaining top rankings for a site is like winning a battle. It’s often the small, overlooked things which make the difference and that are what SEO is for. At the first instance, SEO seems to be a simple process of playing around with the keywords and links. But then, is that simple? The answer is it’s as simple as moving Mount Fuji or the Himalayas. It demands accuracy with lots of insight into the actual process.</p>
                                <p> To be a successful SEO, the right combination of science and strategy is needed, which is why Kudoss is there for you.</p>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-5">
                        <div className="row section-wrapper">
                            <div className="section-head col-sm-12">
                                <header className="col col-sm-12 centered">
                                    <section>
                                        <h2>We, at Kudoss, offer the following SEO services</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>
                        <div className="row section-wrapper row-eq-height servList">

                            <div className="col-md-4 col-sm-6 col-xs-12 col-sm-6 col-xs-12">
                                <div className="analList">
                                    <p className="list-had">Initial Analysis</p>
                                    <ul className="custom-points ml-4">
                                        <li>	Website and Webpage Analysis</li>
                                        <li>	Keyword &amp; Key-phrase Analysis</li>
                                        <li>	Competitor Website Analysis</li>
                                        <li>	Content Duplicacy Check</li>
                                        <li>	Initial Rank Report</li>
                                        <li>	Broken Link Check</li>
                                        <li>	Backlink Analysis</li>
                                        <li>	Google Penalty Check</li>
                                        <li>	Traffic  Analysis</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 col-sm-6 col-xs-12"><div className="analList">
                                <p className="list-had">On Page Optimization</p>
                                <ul className="custom-points ml-4">
                                    <li>	Canonicalization </li>
                                    <li>	Website page load optimization </li>
                                    <li>	Header Tag optimization </li>
                                    <li>	W3C Validation check </li>
                                    <li>	Internal link structuring &amp; optimization </li>
                                    <li>	Page Hx tags optimization </li>
                                    <li>	Image &amp; hyperlink optimization </li>
                                    <li>	Robots.txt creation &amp; analysis </li>
                                    <li>	HTML &amp; XML, GEO sitemaps </li>
                                    <li>	Google &amp; Bing webmaster tool </li>
                                    <li>	Google Analytics </li>
                                    <li>	Address Tag Integration </li>
                                    <li>	Keyword density analysis &amp; content optimization </li>
                                    <li>	RSS feed creation </li>
                                    <li>	Website SEO Content Recommendation  </li>
                                </ul>
                            </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 col-sm-6 col-xs-12"><div className="analList">
                                <p className="list-had">Off Page + Content Marketing</p>
                                <ul className="custom-points ml-4">
                                    <li>	Official Blog Setup </li>
                                    <li>	Informational article writing &amp; submission </li>
                                    <li>	Press release writing and submission </li>
                                    <li>	Guest blog post writing &amp; posting </li>
                                    <li>	Social bookmarking </li>
                                    <li>	Web 2.0 </li>
                                    <li>	Infographics design &amp; submission </li>
                                    <li>	Blog commenting </li>
                                    <li>	Video creation </li>
                                </ul>
                            </div>
                            </div>

                        </div>
                        <div className="mt-5">
                            <p>We can make sure you reach the top of Google rankings and also stay at the top. You can rely on us, as you deserve to be on the top!</p>
                        </div>
                    </div>

                </div>
            </Theme>
        )
    }
}
