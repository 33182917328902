import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Theme from './theme';

export default class SMM extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Theme>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Social Media Marketing & SMO Company India | Kudoss IT Solutions</title>
                    <meta name="description"
                        content="To succeed in the internet world and to generate more business and traffic, Kudoss IT Solutions will have you with its social media marketing strategy & social media services" />
                    <meta name="keywords"
                        content="Social Media Marketing Agency, Social Media Company in India, Social Media Marketing Company,SMM Services, SMM Services in India, Social Media Marketing, Social Media Marketing in India,Social Media Marketing Company in India, Social Media Agency, Social Media Agency in India,Social Media Expert, Social Media Expert India, Social Media Expert in India,SMM Services India, Social Media Marketing Services,SMO Services" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="wrapper smm">
                    <div className="container">
                        <div class="row section-wrapper">
                            <div class="section-head col-sm-12">
                                <header class="col col-sm-12 centered">
                                    <section>
                                        <h2>Social Media Marketing (SMM)</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>
                        <div class="row section-wrapper">
                            <div class="col-sm-12">
                                <p>Social Media Marketing is the process of generating website traffic through social media sites.</p>
                                <p>To succeed in the internet world, you need to create the right content that connects with the audience and keeps them engaged. Social networking sites and blogs are great source of interaction and sharing of information. These sites have become an important part of most online marketing campaigns. Social media marketing provides great opportunity to reach your target audience directly and to generate buzz around your brand. The interactive parts of social media allow customers the opportunity to ask questions and empower them to be heard. However, it is essential to have the right strategy in place so as to make optimum use of this resource. Kudoss offers our expert service to keep your business “socially alive”.</p>
                                <p>Social Media marketing helps to build increased brand recognition and improves brand loyalty. It also helps to build opportunity to convert prospects to customers, and improves search engine rankings.</p>
                                <p>We can design the right content with the right strategy to reach the right people at the right time through the right medium.</p>
                                <p>Our Social Media service offered includes:</p>
                                <ul className="custom-points ml-5">
                                    <li>Social Media Strategy Development</li>
                                    <li>Content Development</li>
                                    <li>Content Distribution</li>
                                    <li>Community Building</li>
                                    <li>Social Media Measurement</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Theme>
        )
    }
}
