import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import routes from './router';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from 'redux-thunk';
import reducers from "./reducers";
import "./Styles/main";
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase from "firebase";

firebase.initializeApp({ 
    apiKey: "AIzaSyCcKUykjHjWEdMeXHG7krt-4tGPMUE9F9Q",
    authDomain: "kudossweb.firebaseapp.com",
    databaseURL: "https://kudossweb.firebaseio.com",
    projectId: "kudossweb",
    storageBucket: "kudossweb.appspot.com",
    messagingSenderId: "686511118345",
    appId: "1:686511118345:web:8dd228e6515dd0df"
 });
const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

ReactDOM.render(
    <Provider store={createStoreWithMiddleware(reducers)}>
        <BrowserRouter>
            {routes}
        </BrowserRouter>
    </Provider>, document.getElementById('root'));
registerServiceWorker();
