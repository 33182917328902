import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Theme from './theme';

export default class CMSDev extends Component {
    render() {
        return (
            <Theme>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Kudoss IT Solutions Content Management Systems Company India|CMS Web Solutions</title>
                    <meta name="description"
                        content="Kudoss IT Solutions a CMS website development company in India. With CMS solutions & CMS services we will manage the valuable content of your website." />
                    <meta name="keywords"
                        content="CMS Website Development Company, Content Management system services Company india, CMS in India, cms development, content management solutions,CMS solutions india, CMS services india, CMS Website Development Company india, Content Management system india" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="wrapper loadGen">
                    <div className="container">
                        <div class="row section-wrapper">
                            <div class="section-head col-sm-12">
                                <header class="col col-sm-12 centered">
                                    <section>
                                        <h2>Kudoss Content Management System</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>
                        <div class="row section-wrapper">
                            <div class="col-sm-12">
                                <p>Are you paying and waiting endlessly to get your website updated, to get its content changed and waiting to take charge of your website, then certainly Kudoss's CMS website development services is the one. Kudoss provides various type of Content Management system which will definitely makes website maintenance easy and cost effective.</p>
                                <p>With CMS (Kudoss Content Management System) you can meet custom web designing. Whatever changes, look or any modification you want to make in your website could be made possible with the help of advanced Content Management System program. You don’t have to wait for someone for the changes you wish to do in your website, with CMS you have the complete control of your website and can solve every hustle related to website development.</p>
                                <p>With Kudoss Content Management System you don’t have to wait to do minor changes or to edit, add or delete any of your website pages.Kudoss smart CMS program will keep your site information updated.</p>
                                <h4>Build a dynamic website with easy to use content management system.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </Theme>
        )
    }
}
