import React, { Component } from 'react';
import { connect } from "react-redux";
import firebase from "firebase";
import RichTextEditor from 'react-rte';
import Loader from '../components/loader';
import Images from "../util/images";

class UpdateBlog extends Component {

    state = {
        blog: null,
        value: RichTextEditor.createEmptyValue(),
        image: null,
        title: "",
        content: "",
        url: "",
        loader: false,
    }

    componentDidMount() {

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
            } else {
                this.props.history.push("/login")
            }
        });
        if (this.props.selectedBlog) {
            this.setState({
                blog: this.props.selectedBlog,
                title: this.props.selectedBlog.title,
                content: this.props.selectedBlog.content,
                value: RichTextEditor.createValueFromString(this.props.selectedBlog.content, 'html')
            })
        }
    }

    uploadImage = (event) => {
        this.setState({
            image: event.target.files[0]
        })
    }

    onSubmit = () => {
        this.setState({
            loader: true
        });
        let blogDetails = {
            title: this.state.title,
            content: this.state.content,
        };
        if (this.state.image) {
            firebase.storage().ref().child(this.state.image.name).put(this.state.image)
                .then((snapshot) => {
                    return snapshot.ref.getDownloadURL()
                })
                .then((downloadURL) => {
                    blogDetails.image_url = downloadURL;
                    firebase.firestore().collection("blogs").doc(this.state.blog.id).update(blogDetails)
                        .then(() => {
                            this.setState({
                                loader: false
                            });
                            this.props.history.push("/ManageBlogs")
                        })
                        .catch((error) => {
                            this.setState({
                                loader: false
                            });
                        })

                })
        } else {
            firebase.firestore().collection("blogs").doc(this.state.blog.id).update(blogDetails)
                .then(() => {
                    this.setState({
                        loader: false
                    });
                    this.props.history.push("/ManageBlogs")
                })
                .catch((error) => {
                    this.setState({
                        loader: false
                    });
                })
        }
    }

    onCreate = () => {
        this.setState({
            loader: true
        });
        const id = this.state.url;
        let blogDetails = {
            id: id,
            title: this.state.title,
            content: this.state.content,
        };
        if (this.state.image) {
            firebase.storage().ref().child(this.state.image.name).put(this.state.image)
                .then((snapshot) => {
                    return snapshot.ref.getDownloadURL()
                })
                .then((downloadURL) => {
                    blogDetails.image_url = downloadURL;
                    firebase.firestore().collection("blogs").doc(id).set(blogDetails)
                        .then(() => {
                            this.setState({
                                loader: false
                            });
                            this.props.history.push("/ManageBlogs")
                        })
                        .catch((error) => {
                            this.setState({
                                loader: false
                            });
                        })
                })
        } else {
            firebase.firestore().collection("blogs").doc(id).set(blogDetails)
                .then(() => {
                    this.setState({
                        loader: false
                    });
                    this.props.history.push("/ManageBlogs")
                })
                .catch((error) => {
                    this.setState({
                        loader: false
                    });
                })
        }
    }

    onChange = (value) => {
        this.setState({
            value,
            content: value.toString('html')
        })
    }

    renderCreateBlog() {
        return (
            <div className="container">
                <div className="mt-5" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <input className="my-5" onChange={(e) => this.uploadImage(e)} type="file" />
                    <div className="input-group input-group-sm mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroup-sizing-sm">url </span>
                        </div>
                        <input onChange={(event) => this.setState({ url: event.target.value })} type="text" />
                         (Please do not use spaces and special character)
                    </div>
                    <div className="input-group input-group-sm mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Title</span>
                        </div>
                        <input onChange={(event) => this.setState({ title: event.target.value })} type="text" />
                    </div>
                    <div className="mt-3" style={{ width: "100%" }}>
                        Content
                        <RichTextEditor
                            value={this.state.value}
                            onChange={this.onChange}
                        />
                        <button className="my-5" onClick={() => this.onCreate()}>Create</button>
                    </div>
                </div>
                <div>

                </div>

            </div>
        )
    }

    renderBlogDetails() {
        return (
            <div className="container">
                <div className="mt-5" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ borderWidth: 1, padding: 15, borderColor: "red" }}>
                        <img width={500} height={300} src={this.state.blog.image_url} alt="blog-pic" />
                    </div>
                    <input className="my-3" onChange={(e) => this.uploadImage(e)} type="file" />

                    <div className="input-group input-group-sm mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Title</span>
                        </div>

                        <input onChange={(event) => this.setState({ title: event.target.value })} value={this.state.title} type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
                    </div>
                    <div className="mt-3" style={{ width: "100%" }}>
                        Content
                        <RichTextEditor
                            value={this.state.value}
                            onChange={this.onChange}
                        />
                        <button className="my-5" onClick={() => this.onSubmit()}>Save</button>
                    </div>
                </div>
                <div>

                </div>

            </div>
        )
    }

    render() {
        if (this.state.loader) {
            return (
                <div>
                    <Loader />
                </div>
            )
        } else {
            return (
                <div className="theme-container">
                    <img className="theme-image" src={Images.slider1} alt="theme" />
                    {this.state.blog ? this.renderBlogDetails() : this.renderCreateBlog()}
                </div>
            )
        }

    }
}

function mapStateToProps(state) {
    return {
        selectedBlog: state.data.SELECTED_BLOG,
    }

}

export default connect(mapStateToProps, null)(UpdateBlog);
