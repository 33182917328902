import React, { Component } from 'react';
import Images from "../util/images";

export default class Theme extends Component {

    render() {

        return (
            <div className="theme-container">
                <img className="theme-image" src={Images.slider1} alt="theme" />
                <div className="my-5">
                    {this.props.children}
                </div>
            </div>
        )
    }
}
