import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Theme from './theme';

export default class LocalMarketing extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Theme>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Local SEO Company and local seo marketing services India at Kudoss IT Solutions</title>
                    <meta name="description"
                        content="Kudoss IT Solutions is expert in local seo, local marketing services and local seo marketing services to generate local leads and enhance the traffic and business rank higher in your geographical location." />
                    <meta name="keywords"
                        content="Local SEO Services,Local SEO Company India, Local SEO Agency,local marketing services, local seo marketing services, local marketing services india, local seo marketing services india" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="wrapper localMaketing">
                    <div className="container">
                        <div class="row section-wrapper">
                            <div class="section-head col-sm-12">
                                <header class="col col-sm-12 centered">
                                    <section>
                                        <h2>Local Marketing</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>
                        <div class="row section-wrapper">
                            <div class="col-sm-12">
                                <p>An online local ranking along with reliable local search engine marketing is vital to your business’s growth. Your future customers are searching the web to find products and services available nearby them. Appearing in their search results depends on the strength of your local internet marketing. This can be done by Kudoss through its consistent local marketing service.</p>
                                <p>Our local marketing service includes :</p>
                                <ul className="custom-points ml-5">
                                    <li>local keyword analysis</li>
                                    <li>full local SEO optimization</li>
                                    <li>creating the right local content for the location</li>
                                    <li>organic link building and internal linking strategy</li>
                                    <li>Identifying the right sites to promote your business locally</li>
                                    <li>local online marketing plan.</li>
                                </ul>
                                <p>We provide optimized website, search engine placement, social media presence, and ongoing marketing support. With Kudoss, your business can be glocal – globally local, attending to both global and local needs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Theme>
        )
    }
}
