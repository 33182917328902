import React from 'react';

const FlipBox = (props) => {
    const point = props.point;
    
    return(
        <div className="flip-box col-sm-6 col-xs-12 col-md-6 col-lg-3">
            <div className="flip-box-inner">
                <div className="flip-box-front p-5">
                    <h3 style={{color: "#2ba3ff"}}>{point}</h3>
                    <h6 className="mt-3">{props.header}</h6>
                </div>
                <div className="flip-box-back mt-3">
                    <p className="p-3">
                        {props.children}
                    </p>
                </div>
            </div>
        </div>

    );
}

export default FlipBox;