import React, { Component } from 'react';
import Header from './header/header';
import Footer from './footer';

class App extends Component {

	render = () => {
		console.log(window.location.pathname.search("admin"))
		if (window.location.pathname.search("admin") !== -1) {
			return (
				<div className="image-background">
					{this.props.children}
				</div>
			);
		} else {

			return (
				<div className="image-background">

					<Header {...this.props} />

					{this.props.children}
					<Footer />
				</div>
			);
		}
	}
}


export default App;