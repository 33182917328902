import {
    SELECTED_BLOG,
} from "../actions";

export default function (state = {}, action) {
    switch (action.type) {
        case SELECTED_BLOG:
            return { ...state, SELECTED_BLOG: action.data };
        default:
            return state;
    }
}