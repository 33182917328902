import React, { Component } from 'react'
import ClipLoader from 'react-spinners/ClipLoader';

export default class Loader extends Component {
    render() {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
            }}
                className='sweet-loading'>
                <ClipLoader
                    sizeUnit={"px"}
                    size={150}
                    color={'#123abc'}
                    loading={true}
                />
            </div>
        )
    }
}
