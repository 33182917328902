import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Theme from './theme';

export default class SEOAudits extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Theme>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title> Kudoss IT Solutions offers SEO Audit Services | SEO Services India</title>
                    <meta name="description"
                        content="Kudoss IT Solutions a SEO Company provides SEO Audit Services in India and plans for better performance of the site. Being a professional SEO Company India, we deliver best results." />
                    <meta name="keywords"
                        content="SEO India, SEO Audit Services,Technical SEO Services, SEO Company India, SEO Website Audit Service India, SEO Audit Services India,SEO Company India" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="seoAudit wrapper">
                    <div className="container">
                        <div className="row section-wrapper">
                            <div className="section-head col-sm-12">
                                <header className="col col-sm-12 centered">
                                    <section>
                                        <h2>SEO Audits</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>
                        <div className="row section-wrapper">
                            <div className="col-sm-12">
                                <p>SEO audit of your website is essential to identify problem areas that need improvement. A good SEO audit aims at keeping the website up to date with the latest developments and to give an edge over the competition.</p>
                                <p>SEO audit is the process of evaluating the website’s friendliness and visibility in line with your SEO efforts. Our expert auditor will review the website and offer the recommendations of the needed fixes so as to improve the overall performance of the website.</p>
                                <p>We offer the following things after our SEO audit: not We, at Kudoss, offer the following SEO services</p>
                                <ul className="custom-points ml-5">
                                    <li> The current state of your website – detailed analysis of performance in search, social media.</li>
                                    <li> List of actions with explanations on how performance can be improved.</li>
                                    <li> Complete Internet Marketing Strategy</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Theme>
        )
    }
}
