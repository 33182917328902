import React from 'react';
import Images from "../util/images";
import { Link } from 'react-router-dom';

// eslint-disable-next-line
const footer = () => {
    if (window.location.pathname === '/login' || window.location.pathname === '/ManageBlogs') {
        return null
    } else {
        return (
            <section className="bottom_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 recent-portfolio">
                            <div className="row">
                                <div className="section-head col-sm-12">
                                    <header className="col col-sm-12 centered">
                                        <h3>Follow Us</h3>
                                        <hr />
                                    </header>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }} className="row">
                                <ul className="list-inline social">
                                    <li><a className="icon-facebook" href="http://kudossweb.com/"> </a></li>
                                    {/* <li><a className="icon-gplus" href="http://kudossweb.com/"></a></li> */}
                                    <li><a className="icon-twitter" href="http://kudossweb.com/"> </a></li>
                                    {/* <li><a className="icon-dribbble" href="http://kudossweb.com/"></a></li> */}
                                    {/* <li><a className="icon-vimeo" href="http://kudossweb.com/"></a></li> */}
                                    {/* <li><a className="icon-lastfm" href="http://kudossweb.com/"></a></li> */}
                                    <li><a className="icon-linkedin" href="http://kudossweb.com/"> </a></li>
                                    <li><a className="icon-skype" href="http://kudossweb.com/"> </a></li>
                                    <li><a className="icon-youtube" href="http://kudossweb.com/"> </a></li>
                                    {/* <li><a className="icon-deviantart" href="http://kudossweb.com/"></a></li>
                                <li><a className="icon-rss" href="http://kudossweb.com/"></a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 about-us">
                            <div className="row">
                                <div className="section-head col-sm-12">
                                    <header className="col col-sm-12 centered">
                                        <h3>About Us</h3>
                                        <hr />
                                    </header>
                                </div>
                            </div>
                            <div className="row">
                                <div className=" col-sm-12">
                                    <div className="logo-area mb-3 px-3">
                                        <Link to="/"><img src={Images.logo} alt="site logo" /></Link>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <p className="px-3">We are a creative Digital Marketing Agency with a team of experts and professionals focused on innovative, flexible and result-driven digital solution. We are offering services in SEO, SEM, Social Media, PPC, Online Marketing, Website Design and Development, Website Conversion, E-commerce, Mobile Applications and Software Development. We are a company of Passionate Individuals.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row mt-5">
                        <span>© Copyright kudossweb 2021. All rights reserved.</span>
                    </div>
                </div>

            </section>
        );
    }
}

export default footer;