import React from 'react';

const Article = () => {
    return (
        <div className="container text-center">
            <div className="row section-wrapper">
                <div className="section-head col-sm-12">
                    <header className="col col-sm-12 centered">
                        <section>
                            <h2>Welcome To Kudoss</h2>
                        </section>
                        <hr />
                    </header>
                </div>
            </div>
            <div className="row section-wrapper">
                <div className="col-sm-12">
                    <p>“Marketing without design is Daydream and design without marketing is Nightmare”</p>
                    <p>We are a creative Digital Marketing Agency with a team of experts and professionals focused on innovative, flexible and result-driven digital solution. We are offering services in SEO, SEM, Social Media, PPC, Online Marketing, Website Design and Development, Website Conversion, E-commerce, Mobile Applications and Software Development. We are a company of Passionate Individuals.</p>
                </div>
            </div>
        </div>
    );
}

export default Article;