import React, { Component } from 'react'
import Images from "../util/images";
import firebase from "firebase";
import Loader from '../components/loader';

export default class Blogs extends Component {

    state = {
        blogs: [],
        loading: true,
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        firebase.firestore().collection("blogs")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const blog = {
                        id: doc.id,
                        ...doc.data()
                    }
                    this.setState({
                        blogs: [...this.state.blogs, blog],
                    })
                });
                this.setState({ loading: false })
            })
            .catch(() => {
                this.setState({
                    loading: false
                })
            })
    }

    handleRedirection = (blog) => {
        this.props.history.push(`/Blog/${blog.id}`)
    }

    renderBlogs() {
        return this.state.blogs.map((blog, index) => {
            return (
                <div key={index} className="col-sm-6">
                    <div className="masonry-item">
                        <figure>
                            <div className="top-image">
                                <img src={blog.image_url}
                                    alt="how to create break fast food delicious" />
                            </div>
                            <div className="thumb-image">
                                <img src={blog.image_url} alt="thumb" />
                            </div>
                            <figcaption>
                                <div className="description">
                                    <p onClick={() => this.handleRedirection(blog)} href="single_post.html" className="post-title clickable-link">{blog.title}</p>
                                    <p dangerouslySetInnerHTML={{ __html: blog.content }} ></p>
                                </div>
                            </figcaption>
                        </figure>

                    </div>
                </div >
            )
        })
    }
    render() {
        return (
            // <Theme>
            <div className="theme-container">
                <img className="theme-image" src={Images.slider1} alt="theme" />
                {this.state.loading ? <Loader /> : null}
                {this.state.blogs.length ?
                    <div className="blog-masonry-container blog_classic my-5" >
                        <section className="blog-masonry col-md-12">
                            <div className="row">
                                <div className=" container">
                                    <div id="blog-masonry" className="row">
                                        {this.renderBlogs()}
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="paging-nav col-xs-12">
                                            <span className="float-left">
                                                <span className="paging-inline">
                                                    <div className="btn-arrow">
                                                        <i className="icon-left-open-big"></i>
                                                    </div>
                                                </span>
                                                <span className="paging-text paging-inline">
                                                    Older Post
                                                </span>
                                            </span>
                                            <span className="float-right paging-disabled">
                                                <span className="paging-text paging-inline">Newer Post</span>
                                                <span className="btn-arrow paging-inline">
                                                    <i className="icon-right-open-big"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                    : null
                }
            </div>
            // </Theme>
        )
    }
}
