import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Slider from "../components/carousel";
import Article from "../components/article";
import FlipBoxes from "../components/flipBoxes";
import Images from "../util/images";
import OurServices from '../components/ourServices';
import Works from '../components/works';

export default class Home extends Component {

  state = {
    data: [
      {
        img: Images.slider1,
        text: "THE SEO EXPERTS"
      },
      {
        img: Images.slider2,
        text: "WEB DEVELOPMENT"
      },
      {
        img: Images.slider3,
        text: "MOBILE APP DEVELOPMENT"
      }
    ]
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title> Kudoss IT Solutions Digital Marketing Agency |Internet Marketing Services India </title>
          <meta name="description"
            content="Kudoss IT Solutions one of the best digital marketing agency in Indore, Ahmedabad India. Excel in SEO, Social Media, Website & Software Development, Content Marketing, Mobile App." />
          <meta name="keywords"
            content="digital marketing agency in india, digital marketing agency in ahmedabad, digital marketing company in India, digital marketing company in india, digital marketing companies in indore, seo services, social media marketing, digital marketing services" />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Slider data={this.state.data} />
        <div style={{ backgroundColor: "#ffffffa8" }} className="homePage pb-5">
          <div style={{}}>
            <div style={{ backgroundColor: "#ecf0f1", paddingBottom: 50 }}>
              <div className="home-container container pt-5">
                <Article />
              </div >
            </div>
            <div >
              <OurServices />
            </div>
            <Works />
            <div className="container">
              <div className="row" style={{ display: "flex", justifyContent: "center", }}>
                <div className="strategic-head" style={{ marginBottom: 0 }} >
                  <header className="col col-xs-12 centered">
                    <section>
                      <h2>Our Strategic Approach to Prepare a Brand Grow Online</h2>
                    </section>
                    <hr />
                  </header>
                </div>
              </div>

              <div className="">
                <FlipBoxes />
              </div>
            </div>
          </div>




        </div>
      </div>

    )
  }
}
