import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Theme from './theme';

export default class SEM extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Theme>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Kudoss IT Solutions a Search Engine Marketing Company and offers SEM Services India.</title>
                    <meta name="description"
                        content="Kudoss IT Solutions SEM Services will definitely help you to attain web traffic, at the same time Search engine marketing (SEM) solutions will help to increase your business sales." />
                    <meta name="keywords"
                        content="search engine marketing, sem services,Search Engine Marketing India,SEM Services in India,Search Engine Marketing Company,Search Engine Marketing Company India,sem services company India, sem company" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="wrapper sem">
                    <div className="container">
                        <div class="row section-wrapper">
                            <div class="section-head col-sm-12">
                                <header class="col col-sm-12 centered">
                                    <section>
                                        <h2>Search Engine Marketing (SEM)</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>
                        <div class="row section-wrapper">
                            <div class="col-sm-12">
                                <p>Search Engine Marketing is the procedure of generating website traffic by buying ads on search engines. Now a days, SEM is commonly used to refer to paid search.</p>
                                <p>It requires use of powerful tools and methodologies for promoting products and services through the Internet.</p>
                                <p>Through our result driven services and customer friendly approach, Kudoss offers full marketing service to propel the promotion and sales of your products. Our expertise is all you need to have a solid lead generator for your business.</p>
                                <p>With Kudoss as your partner, be ready for some serious web traffic coming to your site!</p>
                                <p>With a reliable marketing partner like Kudoss, SEM can be a great strategic tool to attract web traffic and sales.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Theme>
        )
    }
}
