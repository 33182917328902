import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class Slider extends Component {

  render = () => {
    return (
      <div className="carousel-container">
        <Carousel showArrows={true} autoPlay={true} showIndicators={true} infiniteLoop={true} showStatus={false} emulateTouch={true} showThumbs={false}>
          {this.props.data.map((value, index) => {
            return (
              <div className="carousal-slider" key={index}>
                <img src={value.img} height="100%" style={{ objectFit: "cover" }} alt="carosel" />
                <p className="legend">{value.text}</p>
              </div>
            )
          })
          }
        </Carousel>
      </div>
    );
  }
}

export default Slider;
