import React, { Component } from 'react';

export default class OurServices extends Component {

    render() {
        return (
            <div className="pt-5" style={{}}>
                <section className="dt-services-section">
                    <div className="container">
                        <div className="row" style={{ display: "flex", justifyContent: "center", }}>
                            <div className="section-head" style={{ marginBottom: 0 }} >
                                <header className="col col-xs-12 centered">
                                    <section>
                                        <h2>Our Services</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>

                        <div className="row services">
                            <div className="col-12 col-md-6 col-sm-12 col-xs-12" data-uk-scrollspy="{cls:'uk-animation-fade', delay:1000}">
                                <div className="dt-service">
                                    <div className="dt-section-icon hi-icon-wrap hi-icon-effect-5 hi-icon-effect-5d"><i className="hi-icon icon-laptop-2"></i></div>
                                    <h4>WEB DESIGN</h4>
                                    <p>Give your business the edge with Kudoss and be ready to get exciting new leads with Kudoss Website design and Development Services. We also offer customized CMS based website development and E-commerce website development.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-sm-12 col-xs-12" data-uk-scrollspy="{cls:'uk-animation-fade', delay:500}">
                                <div className="dt-service">
                                    <div className="dt-section-icon hi-icon-wrap hi-icon-effect-5 hi-icon-effect-5d"><i className="hi-icon icon-search"></i></div>
                                    <h4>Online Marketing</h4>
                                    <p>Kudoss is a world-class online marketing agency, aimed at providing great ideas and services to our customers and helping them stay on top. We can make sure you reach the top of Google rankings and also stay at the top. You can rely on us, as you deserve to be on the top!</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-sm-12 col-xs-12" data-uk-scrollspy="{cls:'uk-animation-fade', delay:1200}">
                                <div className="dt-service">
                                    <div className="dt-section-icon hi-icon-wrap hi-icon-effect-5 hi-icon-effect-5d"><i className="hi-icon icon-twitter"></i></div>
                                    <h4>Mobile Applications</h4>
                                    <p>Kudoss is expert at creating the brand identity in the mobile space. We at Kudoss are well versed with the latest mobile apps and we can help you to reach your immense potential customers. Partner with Kudoss to leave the right impression and get the right clicks!</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-sm-12 col-xs-12" data-uk-scrollspy="{cls:'uk-animation-fade', delay:900}">
                                <div className="dt-service">
                                    <div className="dt-section-icon hi-icon-wrap hi-icon-effect-5 hi-icon-effect-5d"><i className="hi-icon icon-mouse"></i></div>
                                    <h4>Content writing & Optimization</h4>
                                    <p>Kudoss offers Content Marketing Service which provide quality content, genuinely business relevant and consistently engaging content. Our Content Marketing Service includes : Content approach, Content Development and Content Distribution Services. Rely on Kudoss for all your Content Marketing Services as you deserve the best service for your business. </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-sm-12 col-xs-12" data-uk-scrollspy="{cls:'uk-animation-fade', delay:1500}">
                                <div className="dt-service">
                                    <div className="dt-section-icon hi-icon-wrap hi-icon-effect-5 hi-icon-effect-5d"><i className="hi-icon icon-lifebuoy"></i></div>
                                    <h4>SUPPORT</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
