import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import App from './components/App';
import Home from './containers/home';
import Header from './components/header/header'
import AboutUs from './containers/aboutUs';
import SEO from "./containers/seo";
import SEOAudits from "./containers/SEOAudits";
import SEM from "./containers/SEM";
import SMM from "./containers/SMM";
import PPC from "./containers/PPC";
import LocalMarketing from "./containers/localMarketing";
import LocalGeneration from "./containers/localGeneration";
import ReputationMgn from "./containers/reputationMgn";
import ContactUs from "./components/contactUs";
import Blogs from "./containers/blogs";
import Login from "./containers/login";
import BlogsCRUD from "./containers/blogsCRUD";
import WebsiteDev from "./containers/websiteDev";
import MobileDev from "./containers/mobileDev";
import CMSDev from "./containers/CMSDev";
import UpdateBlog from './containers/updateBlog';
import BlogDetails from "./containers/blogDetails";

export default (
	<App>
		<Switch>
			<Route path="/" exact component={Home} />
			<Route path="/header" exact component={Header} />
			<Route path="/about-us" exact component={AboutUs} />
			<Route path="/search-engine-optimization-india" exact component={SEO} />
			<Route path="/search-engine-marketing-india" exact component={SEM} />
			<Route path="/social-media-marketing-services-india" exact component={SMM} />
			<Route path="/seo-audit-services-india" exact component={SEOAudits} />
			<Route path="/pay-per-click-services-india" exact component={PPC} />
			<Route path="/local-seo-marketing-india" exact component={LocalMarketing} />
			<Route path="/lead-generation-company-india" exact component={LocalGeneration} />
			<Route path="/online-reputation-management-india" exact component={ReputationMgn} />
			<Route path="/web-design-company-india" exact component={WebsiteDev} />
			<Route path="/mobile-app-development-india" exact component={MobileDev} />
			<Route path="/content-management-systems-india" exact component={CMSDev} />
			<Route path="/ContactUs" exact component={ContactUs} />
			<Route path="/Blogs" exact component={Blogs} />
			<Route path="/Blog/:id" exact component={BlogDetails} />
			<Route path="/login" exact component={Login} />
			<Route path="/ManageBlogs" exact component={BlogsCRUD} />
			<Route path="/updateBlog" exact component={UpdateBlog} />
			{/* <Route component={Home} /> */}
			<Redirect from='*' to='/' />
		</Switch>
	</App>
);