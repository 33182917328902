import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import Theme from "./theme";

export default class AboutUs extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Theme>
        <div className="about_us container">
          <Helmet>
            <meta charSet="utf-8" />
            <title> Digital Marketing Services India, SEO Company India | Kudoss IT Solutions</title>
            <meta name="description"
              content="Kudoss IT Solutions is a world-class Digital Marketing Agency India , Internet Marketing Services, SEO company India specializing in SEO, Local SEO, Paid Marketing,Mobile App Development,Software Development & Social Marketing." />
            <meta name="keywords"
              content="Digital Marketing Services, SEO Company India, Digital Marketing Agency India, Internet Marketing Services India, Local SEO Services, ppc services,Mobile App Development India,Software Development Company India, Social Marketing Services, Mobile app development company india" />
            <link rel="canonical" href={window.location.href} />
          </Helmet>
          {/* <div className="wrapper"></div> */}
          <div className="wrapper">
            <div className="container">
              <div className="row section-wrapper">
                <div className="section-head col-sm-12">
                  <header className="col col-sm-12 centered">
                    <section>
                      <h2>About Us</h2>
                    </section>
                    <hr />
                  </header>
                </div>
              </div>
              <div className="row section-wrapper">
                <div className="col-sm-12">
                  <p> Kudoss is a Digital Marketing Agency founded by group of experts and professionals. The venture is a result of the hard work and rich experience of our team. The company was founded with an aim to provide the best online marketing service to customers, helping them build their brand in the digital market space.</p>
                  <p> Today, companies have to go beyond just putting a website, it should be able to generate sufficient web traffic to promote their brands and reach consumers. We, at Kudoss, believe in the immense power of internet and we have the expertise to take you to the pinnacle of online marketing.</p>
                  <p> So, look no further, Kudoss is at your service as your digital marketing partner.</p>
                  <p> Let’s shake up the digital market space! Are you ready to grow digitally? </p>
                </div>
              </div>
            </div>
            <div className="container mt-5">
              <div className="row section-wrapper">
                <div className="section-head col-sm-12">
                  <header className="col col-sm-12 centered">
                    <section>
                      <h2>Why Kudoss</h2>
                    </section>
                    <hr />
                  </header>
                </div>
              </div>
              <div className="row section-wrapper">
                <div className="col-sm-12">
                  <p>Kudoss is a world-class internet marketing agency, aimed at providing great ideas and services to our partners and helping them stay on top.</p>
                  <p>Here are few of the several reasons why to have Kudoss as your partner:</p>
                  <ul>
                    <li>	Get  top rankings for your preferred key-phrases </li>
                    <li>	Get quality leads and grow your sales </li>
                    <li>	Get top class services which meet your budget expectations </li>
                    <li>	Get the customized right internet marketing plan for your business. </li>
                    <li>	Be assured of your site being Panda &amp; Penguin safe. </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="container my-5">
              <div className="row section-wrapper">
                <div className="section-head col-sm-12">
                  <header className="col col-sm-12 centered">
                    <section>
                      <h2>Our Mission</h2>
                    </section>
                    <hr />
                  </header>
                </div>
              </div>
              <div className="row section-wrapper">
                <div className="col-sm-12">
                  <p>To help our customers realize the power of branding digitally, and expand their outreach in the digital marketing world.</p>
                </div>
              </div>
            </div>
          </div>

        </div>


      </Theme>
    )
  }
}
