import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Theme from './theme';

export default class PPC extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Theme>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Kudoss IT Solutions offers Pay Per Click Management Services India & Outsource PPC Services</title>
                    <meta name="description"
                        content="Maximize your sales & optimum ROI with Kudoss IT Solutions best ppc services India. We are a pay per click management company offering PPC campaigns efficiently." />
                    <meta name="keywords"
                        content="pay per click advertising india, pay per click advertising company india, pay per click management services india, pay per click management india, ppc services india, pay per click india, ppc services company, ppc company, ppc maintenance" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="wrapper ppc">
                    <div className="container">
                        <div class="row section-wrapper">
                            <div class="section-head col-sm-12">
                                <header class="col col-sm-12 centered">
                                    <section>
                                        <h2>Pay Per Click (PPC) Management</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>
                        <div class="row section-wrapper">
                            <div class="col-sm-12">
                                <p>Getting noticed among thousands of other businesses is the basis to selling goods online. Pay per click (PPC) or cost per click (CPC) is the online advertising model where payment is made to direct traffic to websites upon each click on the advertisement. Basically, it is placing ads in the search results associated with search engines.</p>
                                <p>Pay per click market is growing and the average cost per click is also increasing at a fast rate. Some of the advantages of PPC campaign include:</p>
                                <ul className="custom-points ml-5">
                                    <li>Get Fast Results – usually within a day or two</li>
                                    <li>Highly targetable and results can be measured</li>
                                    <li>Pay only when a prospective customer clicks on your ad</li>
                                    <li>Fastest way of promoting your products and services</li>
                                    <li>Cost effective as yo can set your bidding amount as per your budget</li>
                                </ul>
                                <p>We can help form the strategy to generate quality pay per clicks by creating a customized solution for your business. Kudoss team can make your marketing dreams come true. Our knowledgeable and qualified team can build the most cost effective campaign for your business. We can transform underperforming campaigns to high volume campaigns.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Theme>
        )
    }
}
