import React from 'react';
import FlipBox from "../components/flipBox";

const FlipBoxes = () => {
  return (
    <div className="row wrapper flipBoxWrapper">
      <FlipBox header="Analysis/ Audit" point={1}>
        Company Analysis and audit is required to understand your company and to get a clear picture of your industry. By this we determine your company position within your market place with respect to your main competitors. Our team of experts uses a wide range of industry-tested tools, resources and techniques for an accurate analysis of your business, customers and competition.
A systematic audit determines potential obstacles to the performance of your website and helps build the foundation for your wider, digital marketing efforts.
        </FlipBox>
      <FlipBox header="Website Business & Marketing Plan" point={2}>
        Marketing plans are the foundation of product and service success. It is very much required to develop and design marketing plan before approaching towards optimizing your business to deliver the best possible experience
        </FlipBox>
      <FlipBox header="Build Quality Traffic" point={3}>
        Websites are evaluated on the basis of quantity and quality traffic. Which means from where the traffic is coming is important as it will decide whether our website is marketing in a right direction and getting business and getting potential clients. Generating quality and relevant traffic is very much required to have a strong conversion rate.
        </FlipBox>
      <FlipBox header="Engage audience with brand on its website or other online presence" point={4}>
        Right service, product and content relevant to the business will help the website to get potential audience which will automatically increase the audience engagement and business for the website.
        </FlipBox>
      <FlipBox header="Generating leads and customers by converting visitors" point={5}>
        We with our team will bring leads to your business and new customers with the services we offer.
        </FlipBox>
      <FlipBox header="Generate brand loyalty and repeat business" point={6}>
        We do what we say and we suggest the same for our clients too so as to build a loyal relationship with the customers because building customers loyalty matters in business, as “ customers are lifeblood of any business”.
        </FlipBox>
    </div>
  );
}

export default FlipBoxes;