import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Theme from './theme';

export default class LocalGeneration extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Theme>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Lead Generation Company India | Generate Leads with Kudoss IT Solutions</title>
                    <meta name="description"
                        content="Kudoss IT Solutions a lead generation company in India expert in providing lead generation services, online lead generation and business lead generation." />
                    <meta name="keywords"
                        content="Lead Generation Company,  lead generation services, online lead generation,business lead generation, lead generation agency, lead generation company india, lead generation agency india, lead generation services india" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="wrapper loadGen">
                    <div className="container">
                        <div class="row section-wrapper">
                            <div class="section-head col-sm-12">
                                <header class="col col-sm-12 centered">
                                    <section>
                                        <h2>Worldwide Quality Lead Generation</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>
                        <div class="row section-wrapper">
                            <div class="col-sm-12">
                                <p>If you are looking for good numbers of leads for your business, then you are in the right place. Kudoss believe in providing high quality lead generation. Our motto is to make you sell more and get more customers.</p>
                                <p>Kudoss is a trusted Digital Marketing Agency specializing in Lead Generation with experienced, professional and smart marketing team and their proven prospecting processes. We help our clients to get new business and new customers and increase their business.</p>
                                <p>At Kudoss, we believe in generating quality leads and better reach which will automatically bring more sales to your business.</p>
                                <p>If you want to generate more sales and acquire more customers then contact KUDOSS. We look forward from hearing from you</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Theme>
        )
    }
}
