import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Theme from './theme';

export default class WebsiteDev extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Theme>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Kudoss IT Solutions Website Designing Company India|Website Development Company India</title>
                    <meta name="description"
                        content="Kudoss IT Solutions have professional and experts  for web designing services , website design company india, website development services india to improve your online sales and performance" />
                    <meta name="keywords"
                        content="Website Designing Company India,Website Development Company India	, Web Designing Company India,web development services india, Website Development India, Website Design india,Web Designing Company India,Website Development Company , Web Designing Company" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="wrapper loadGen">
                    <div className="container">
                        <div class="row section-wrapper">
                            <div class="section-head col-sm-12">
                                <header class="col col-sm-12 centered">
                                    <section>
                                        <h2>Kudoss Website Design and Development Services</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>
                        <div class="row section-wrapper">
                            <div class="col-sm-12">
                                <p>“When you make your imagination and thinking into visual its called DESIGN”</p>
                            </div>
                        </div>
                        <div class="row section-wrapper">
                            <div class="section-head col-sm-12">
                                <header class="col col-sm-12 centered">
                                    <section>
                                        <h2>We Are a Website Designer and a Web Developer</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>
                        <div class="row section-wrapper">
                            <div class="col-sm-12">
                                <p>Why You Prefer Kudoss</p>
                                <p>Kudoss believe in keeping things simple yet trendy and result driven engaging web designs. We design websites which helps the business owners to generate more sales from their websites. We completely understand what a business owner wants from their websites and we tend to deliver that with exceptional client services and complete client satisfaction with our intelligent designs. Our designs and methodology has worked to fulfill client expectation and also helped to boost online conversions and engage customers for business.</p>
                                <p>Be it Mumbai or San Francisco, websites have become the “go to” platform to find the best products and services. Websites are now the best marketing tool worldwide. A good website is a must for every organization.</p>
                                <p>Our professional and well experienced designers and developers will provide you with visually stunning and practical websites which will definitely keep new visitors on your website for quite some time.Our design team is passionate about building simply amazing website designs that will definitely liked and appreciated by the client and by the new visitor.</p>
                                Kudoss offers customized CMS based website development and E-commerce website development. Our services are tailored to each customer’s specific need and with our experts in content management, be assured of getting the right traffic on your site.
                            <p className="list-had mt-5">Our website services include the following:</p>
                                <ul className="custom-points ml-4 mx-5">
                                    <li>Website Designing</li>
                                    <li>Website Redesigning</li>
                                    <li>Responsive Website Design</li>
                                    <li>Database Driven Website</li>
                                    <li>E-mailer Design</li>
                                    <li>Website Maintenanc</li>
                                </ul>
                                <p>Kudoss is the expert in responsive website that generates sales and leads.</p>
                                <p>We will provide you with best solutions for you website development and website maintenance needs.We want to make you feel confident in our ability to provide you the best website design possible.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Theme>
        )
    }
}
