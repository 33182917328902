import React from 'react';
import MobileHeader from './mobileHeader';
import DesktopHeader from './dektopHeader';

const Header = () => {
    if (window.location.pathname === '/login' || window.location.pathname === '/ManageBlogs' || window.location.pathname === '/updateBlog') {
        return null;
    } else {
        return (
            <div>
                <DesktopHeader />
                <MobileHeader />
            </div>
        );
    }

}
export default Header;
