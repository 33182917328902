import React, { Component } from 'react';
import firebase from "firebase";
import { Helmet } from "react-helmet";

import Loader from '../components/loader';

export default class BlogDetails extends Component {

    state = {
        blogImage: "",
        title: "",
        content: "",
        loading: true,
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        firebase.firestore().collection("blogs").doc(id).get()
            .then((response) => {
                const blog = response.data();
                this.setState({
                    blogImage: blog.image_url,
                    title: blog.title,
                    content: blog.content,
                    loading: false
                })

            })
            .catch((error) => {
                this.setState({
                    loading: false
                })
            })

    }

    renderBlog() {
        return (
            <React.Fragment>
                {this.state.title ?
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{this.state.title}</title>
                        <meta property="og:title" content={this.state.title} />
                        <meta property="og:description" content={this.state.content} />
                        <meta property="og:image" content={this.state.blogImage} />
                        <meta property="og:url" content={window.location.href} />
                        <meta name="twitter:card" content="summary_large_image" />


                        <link rel="canonical" href={window.location.href} />
                    </Helmet>
                    : null
                }
                <img src={this.state.blogImage} alt="blog" />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 40,
                        flexDirection: "column",
                        alignItems: "center",
                        width: "80%",
                        alignSelf: "center"
                    }}
                >
                    <h2 style={{ marginBottom: 20, color: "#2ba3ff" }}>{this.state.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: this.state.content }} ></p>
                </div>
            </React.Fragment>
        )
    }

    renderLoader() {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignSelf: "center",
                height: "100vh"
            }}>
                <Loader />
            </div>
        )
    }

    render() {
        return (
            <div className="theme-container">
                {this.state.loading ?
                    this.renderLoader()
                    : null}
                {this.renderBlog()}
            </div>
        );
    }
}
