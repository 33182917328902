import React, { Component } from 'react'

export default class Works extends Component {
    render() {
        return (
            <section className="featured_work hilite col-md-12">
                <div className="container">
                    <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                        <div className="section-head" >
                            <header className="col col-sm-12 centered">
                                <section>
                                    <h2>FEATURED WORK</h2>
                                </section>
                                <hr />
                            </header>
                        </div>
                    </div>
                    <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                        <div id="featured-work" className="owl-carousel">
                            <div className="owl-slide col-sd-12" data-uk-scrollspy="{cls:'uk-animation-scale-down', delay:100}">
                                <div className="top-image">
                                    <img src={require("../Assets/pic1.jpg")} alt="how to create break fast food delicious" />
                                </div>
                                <div className="description">
                                    <div className="slide-content">
                                        <h4>HOW TO CREATE BREAK</h4>
                                        <p>Et, modi libero possimus quos recusandae quibusdam adipisci autem fuga accusantium iste nam.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="owl-slide col-sd-12" data-uk-scrollspy="{cls:'uk-animation-scale-down', delay:500}">
                                <div className="top-image">
                                    <img src={require("../Assets/pic2.jpg")} alt="how to create break fast food delicious" />
                                </div>
                                <div className="description">
                                    <div className="slide-content">
                                        <h4>FAST FOOD DELICIOUS</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio, tempora, veritatis, amet fugit.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="owl-slide col-d-12" data-uk-scrollspy="{cls:'uk-animation-scale-down', delay:1000}">
                                <div className="top-image">
                                    <img src={require("../Assets/pic3.jpg")} alt="how to create break fast food delicious" />
                                </div>
                                <div className="description">
                                    <div className="slide-content">
                                        <h4>HOW TO CREATE DELICIOUS</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint, quia, quasi velit eaque ullam est.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="owl-slide col-sd-12 " data-uk-scrollspy="{cls:'uk-animation-scale-down', delay:1500}">
                                <div className="top-image">
                                    <img src={require("../Assets/pic4.png")} alt="how to create break fast food delicious" />
                                </div>
                                <div className="description">
                                    <div className="slide-content">
                                        <h4>FAST FOOD DELICIOUS</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum, aspernatur, vitae quidem.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="owl-carousel-navigation " >
                            <div className="btn btn-owl prev"></div>
                            <div className="btn btn-owl next"></div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
