import React, { Component } from 'react'
import Theme from '../containers/theme';
import * as emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class ContactUs extends Component {

    state = {
        email: "",
        firstName: "",
        lastName: "",
        message: "",
        contactNo: ""
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    onSubmit() {
        if (this.state.firstName && this.state.lastName && this.state.contactNo && this.state.email && this.state.message) {
            // eslint-disable-next-line
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) && this.state.contactNo.match(/([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/)) {
                emailjs.send(
                    "zoho",
                    "template_JYiZimB4",
                    {
                        first_name: this.state.firstName,
                        last_name: this.state.lastName,
                        contact_no: this.state.contactNo,
                        senders_email: this.state.email,
                        message_html: this.state.message
                    },
                    "user_Bw6j1POJ2pDdX6BgthMQa")
                    .then((res) => {
                        if (res.text === "OK") {
                            toast("Email Sent");
                        } else {
                            toast("Unable to send email");
                        }
                    })
                    .catch((error) => {
                        toast("Unable to send email");
                    })
            } else {
                toast("Email or contact number is invalid");
            }
        } else {
            toast("All input field is mendatory");
        }

    }

    render() {
        return (
            <Theme>
                <ToastContainer />
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <div className="controls">
                        <div className="row" >
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="form_name">Firstname *</label>
                                    <input id="form_name" onChange={(event) => this.setState({ firstName: event.target.value })} type="text" name="name" className="form-control" placeholder="Please enter your firstname *" required="required" data-error="Firstname is required." />
                                    <div className="help-block with-errors"></div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="form_lastname">Lastname *</label>
                                    <input id="form_lastname" type="text" name="surname"
                                        onChange={(event) => this.setState({ lastName: event.target.value })}
                                        className="form-control" placeholder="Please enter your lastname *" required="required" data-error="Lastname is required." />
                                    <div className="help-block with-errors"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="form_email">Email *</label>
                                    <input id="form_email" type="email" name="email" className="form-control"
                                        onChange={(event) => this.setState({ email: event.target.value })}
                                        placeholder="Please enter your email *" required="required" data-error="Valid email is required." />
                                    <div className="help-block with-errors"></div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="form_email">Contact No. *</label>
                                    <input id="form_email" type="email" name="email" className="form-control"
                                        onChange={(event) => this.setState({ contactNo: event.target.value })}
                                        placeholder="Please enter your contact no. *" required="required" data-error="Valid email is required." />
                                    <div className="help-block with-errors"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="form_message">Message *</label>
                                    <textarea id="form_message" name="message" className="form-control"
                                        onChange={(event) => this.setState({ message: event.target.value })}
                                        placeholder="Message for me *" rows="4" required="required" data-error="Please, leave us a message."></textarea>
                                    <div className="help-block with-errors"></div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button onClick={() => this.onSubmit()} type="submit" className="btn btn-success btn-send" value="Send message" >
                                    Send
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="text-muted">
                                    <strong>*</strong> These fields are required.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Theme>
        )
    }
}
