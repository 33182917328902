import React from 'react';
import Images from "../../util/images";
import { Link } from 'react-router-dom'

const toggleHeader = () => {
    document.getElementById('sidebar').classList.add('active');
    document.getElementById('overlay').classList.add('active');
}
const CloseHeader = () => {
    document.getElementById('sidebar').classList.remove('active');
    document.getElementById('overlay').classList.remove('active');
}
const toggleMenu = (id) => {
    document.getElementById(id).classList.toggle('show');

}

const MobileHeader = () => {
    return (
        <div className="mobile-header-area mobile-header">
            <nav className="navbar navbar-expand-lg navbar-light mob_container">

                <div className="container-fluid">
                    {/* <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={toggleHeader}> */}
                    <span id="sidebarCollapse" onClick={toggleHeader}>&#9776;</span>
                    {/* </button> */}
                    <div className="logo-area">
                        <a href="/"><img height={35} src={Images.logo} alt="site logo" /></a>
                    </div>

                </div>
            </nav>
            <nav id="sidebar">
                <div id="dismiss" onClick={CloseHeader}>
                    <i className="fas fa-arrow-left"></i>
                </div>

                <div className="sidebar-header">
                </div>
                <ul>
                    <li><a href="/">Home <span> </span> </a></li>
                    <li><Link to="/about-us">About </Link></li>
                    <li><a href="/SEOsubmenu" data-toggle="collapse" aria-expanded="false" onClick={() => toggleMenu("SEOsubmenu")}>SEO <span></span></a>
                        <ul id="SEOsubmenu" className="list-unstyled collapse">
                            <li><Link to="/search-engine-optimization-india"> Search Engine Optimization (SEO) </Link></li>
                            <li><Link to="/seo-audit-services-india"> SEO Audits </Link></li>
                            <li><Link to="/search-engine-marketing-india"> Search Engine Marketing (SEM) </Link></li>
                            <li><Link to="/social-media-marketing-services-india"> Social Media  Marketing (SMM)</Link></li>
                            <li><Link to="/pay-per-click-services-india"> Pay Per Click (PPC) </Link></li>
                            <li><Link to="/local-seo-marketing-india"> Local Marketing </Link></li>
                            <li><Link to="/lead-generation-company-india"> Lead Generation </Link></li>
                            <li><Link to="/online-reputation-management-india"> Reputation Management </Link></li>
                        </ul>
                    </li>
                    <li><a href="/Servicesubmenu" data-toggle="collapse" onClick={() => toggleMenu("Servicesubmenu")}>Services <span></span> </a>
                        <ul id="Servicesubmenu" className="list-unstyled collapse">
                            <li><Link to="/web-design-company-india"> Website Design and Development</Link></li>
                            <li><Link to="/mobile-app-development-india"> Mobile Development </Link></li>
                            <li><Link to="/content-management-systems-india"> CMS </Link></li>
                        </ul>
                    </li>
                    <li><Link to="/Blogs">Blog <span></span> </Link>
                    </li>
                    <li><Link to="/ContactUs">Contact us <span></span> </Link>
                    </li>
                    {/* <li><a href="contact.html">Offers</a></li> */}
                </ul>
            </nav>
            <div className="overlay" id="overlay" onClick={CloseHeader}></div>
        </div>
    );
}

export default MobileHeader;