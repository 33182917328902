import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Theme from './theme';

export default class MobileDev extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Theme>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Mobile App Development Company India, Android App Development Company India</title>
                    <meta name="description"
                        content="Kudoss IT Solutions is a mobile app development & android app development company in India.We build advanced mobile applications using latest technologies." />
                    <meta name="keywords"
                        content="mobile app development, android app development, mobile app developers, mobile app development company India, app developers India, Android app development india, Mobile Android App Development Company India, Android App Development India,mobile App Development Company India" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="wrapper loadGen">
                    <div className="container">
                        <div class="row section-wrapper">
                            <div class="section-head col-sm-12">
                                <header class="col col-sm-12 centered">
                                    <section>
                                        <h2>Mobile App Development</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>
                        <div class="row section-wrapper">
                            <div class="col-sm-12">
                                <p>Mobile has become an integral part of everyone’s life with the method of discovering, consuming and sharing information and if done exceptionally well then can put your business in a position to profit.</p>
                                <p>Mobile app has already captured 88% of all smartphone activity and around 83% of tablet activity, and in the coming years we are sure that these figures will definitely go up</p>
                                <p>We have a team of expert professionals who will be guiding you through every phase of the product development lifecycle. We will help you and guide you to see what you can’t. Our goal is to not only turn your vision into reality, but to guide you with best practices along the way. Your success is our success.</p>
                                <p>We provide services for clients to generate new revenue channels and maximize their ROI. Kudoss is expert at creating the brand identity in the mobile space.We help you build a business growth machine through growth hacking tactics.</p>
                                <p>So, if you’re looking for an app development company or specialists in mobile app development, there’s no need to conduct a fishing expedition. You’ve found one of the most talented experienced app development companies, Kudoss.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Theme>
        )
    }
}
