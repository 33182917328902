import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Theme from './theme';

export default class ReputationMgn extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Theme>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title> Kudoss IT Solutions Online Reputation Management Company India</title>
                    <meta name="description"
                        content="Kudoss IT Solutions is a leading Online Reputation Management Company. Rely on ORM Company India to give your brand the reputation that it deserves." />
                    <meta name="keywords"
                        content="Online Reputation Management, Reputation Management Services, Brand Management Services, Corporate Reputation Management, Online Reputation Monitoring,ORM services,reputation management, online reputation management india,Online Reputation Management Company	India,Online Reputation Management Company,Online Reputation Management Services,ORM Company India,Outsource Online Reputation india, Outsource Online Reputation" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="wrapper manageGen">
                    <div className="container">
                        <div class="row section-wrapper">
                            <div class="section-head col-sm-12">
                                <header class="col col-sm-12 centered">
                                    <section>
                                        <h2>Reputation Management</h2>
                                    </section>
                                    <hr />
                                </header>
                            </div>
                        </div>
                        <div class="row section-wrapper">
                            <div class="col-sm-12">
                                <p>Reputation management deals with managing the results online on websites that evaluate products and services and make referrals and recommendations. Companies can face hostile review from customers and competitors which leads to posting of negative statements online. Ultimately, the company’s reputation is affected.</p>
                                <p>Kudoss can offer our Reputation management service that covers controlling social media, PR and negative content. We use only white hat SEO techniques to build online reputation.</p>
                                <p>Our online reputation management services include:</p>
                                <ul className="custom-points ml-5">
                                    <li>Identifying the source of negative feedback.</li>
                                    <li>Creating an effective strategy to counter the negatives.</li>
                                    <li>Decreasing the visibility of negative information.</li>
                                    <li>Maintaining brand integrity.</li>
                                    <li>Improving online image with positive information.</li>
                                    <li>Actively monitoring online reputation.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Theme>
        )
    }
}
