import React, { useState } from 'react';
import Images from "../../util/images";
import { Link } from 'react-router-dom';

const DesktopHeader = () => {
    const [isScrolledCss, setIsScrolledCss] = useState("main-header-area-relative");
    window.addEventListener('scroll', () => {
        let y = (window.scrollY || window.pageYOffset)
        if (y > 50) {
            setIsScrolledCss("");
        } else {
            setIsScrolledCss("main-header-area-relative")
        }
    })

    return (
        <div className={`main-header-area ${isScrolledCss}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <div className="logo-area">
                            <a href="/"><img height={45} src={Images.logo} alt="site logo" /></a>
                        </div>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                        <div className="main-menu">
                            <ul>
                                <li><a href="/">Home <span> </span> </a></li>
                                <li><Link to="/about-us">About </Link></li>
                                <li> <Link to="/search-engine-optimization-india">SEO</Link>
                                    <ul>
                                        <li><Link to="/search-engine-optimization-india"> Search Engine Optimization (SEO) </Link></li>
                                        <li><Link to="/seo-audit-services-india"> SEO Audits </Link></li>
                                        <li><Link to="/search-engine-marketing-india"> Search Engine Marketing (SEM) </Link></li>
                                        <li><Link to="/social-media-marketing-services-india"> Social Media  Marketing (SMM)</Link></li>
                                        <li><Link to="/pay-per-click-services-india"> Pay Per Click (PPC) </Link></li>
                                        <li><Link to="/local-seo-marketing-india"> Local Marketing </Link></li>
                                        <li><Link to="/lead-generation-company-india"> Lead Generation </Link></li>
                                        <li><Link to="/online-reputation-management-india"> Reputation Management </Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/web-design-company-india">Services</Link>
                                    <ul>
                                        <li><Link to="/web-design-company-india"> Website Design and Development</Link></li>
                                        <li><Link to="/mobile-app-development-india"> Mobile Development </Link></li>
                                        <li><Link to="/content-management-systems-india"> CMS </Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/Blogs">Blog <span></span> </Link>
                                </li>
                                <li><Link to="/ContactUs">Contact us <span></span> </Link>
                                </li>
                                {/* <li><a href="contact.html">Offers</a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DesktopHeader